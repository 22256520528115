import React, { FC, ReactNode } from "react";
import styled from "styled-components";

interface Props {
  title?: string;
  cx?: number;
  cy?: number;
  r: number;
  onClick: () => void;
  children: ReactNode;
  fill?: string;
}

export const CircularButton: FC<Props> = ({ fill, title, cx, cy, r, onClick, children, ...props }: Props) => (
  <StyledClickableGroup {...props} transform={`translate(${cx ?? 0}, ${cy ?? 0}) scale(${r})`}>
    <StyledShadowButton onClick={() => onClick()}>
      {title && <title>{title}</title>}
      <StyledBackgroundCircle fill={fill} r="1" />
      <g transform="scale(1.4)">{children}</g>
    </StyledShadowButton>
  </StyledClickableGroup>
);

const StyledBackgroundCircle = styled.circle`
  fill: ${props => (props.fill ? props.fill : "#ebe9eaff")};
`;

const StyledClickableGroup = styled.g`
  cursor: pointer;
`;
const StyledShadowButton = styled.g`
  &:hover {
    filter: drop-shadow(0px 0px 0.05px #6e6e6e);
  }
`;
