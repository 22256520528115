import React, { FC } from "react";
import styled from "styled-components";
import { Transform } from "../Transform";

export const ScreenIcon: FC = ({ ...props }) => (
  <>
    <Transform {...props} x={-0.4} y={-0.4} scale={0.1}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="8"
        fill="#434b4d"
        className="bi bi-aspect-ratio-fill"
        viewBox="0 0 25 25">
        <StyledLine xmlns="http://www.w3.org/2000/svg" x1="9.75" y1="14.248" x2="0.75" y2="23.248" />
        <StyledPoly xmlns="http://www.w3.org/2000/svg" points="23.25 7.498 23.25 0.748 16.5 0.748" />
        <StyledPoly xmlns="http://www.w3.org/2000/svg" points="0.75 16.498 0.75 23.248 7.5 23.248" />
        <StyledLine xmlns="http://www.w3.org/2000/svg" x1="23.25" y1="0.748" x2="14.25" y2="9.748" />
      </svg>
    </Transform>
  </>
);

const StyledLine = styled.line`
  fill: none;
  stroke: #535353;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2.5px;
`;

const StyledPoly = styled.polyline`
  fill: none;
  stroke: #535353;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2.5px;
`;
