import React, { FC } from "react";
import styled from "styled-components";
import { Transform } from "../Transform";

export const BearIcon: FC = ({ ...props }) => (
  <>
    <Transform {...props} x={-0.3} y={-0.35} scale={0.1}>
      <svg fill="#535353" xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 25 25">
        <StyledPath xmlns="http://www.w3.org/2000/svg" d="M5.646,6.387a9,9,0,0,1,12.728,0" />
        <StyledPath xmlns="http://www.w3.org/2000/svg" d="M20.236,8.966a9.039,9.039,0,1,1-16.452,0" />
        <StyledLine xmlns="http://www.w3.org/2000/svg" x1="10.51" y1="13.501" x2="13.51" y2="13.501" />
        <StyledPath xmlns="http://www.w3.org/2000/svg" d="M7.51,15.751a2.25,2.25,0,0,0,4.5,0" />
        <StyledPath xmlns="http://www.w3.org/2000/svg" d="M16.51,15.751a2.25,2.25,0,0,1-4.5,0V13.5" />
        <StyledPath xmlns="http://www.w3.org/2000/svg" d="M7.885,9.751a.375.375,0,0,1,.375.375" />
        <StyledPath xmlns="http://www.w3.org/2000/svg" d="M7.51,10.126a.375.375,0,0,1,.375-.375" />
        <StyledPath xmlns="http://www.w3.org/2000/svg" d="M7.885,10.5a.375.375,0,0,1-.375-.375" />
        <StyledPath xmlns="http://www.w3.org/2000/svg" d="M8.26,10.126a.375.375,0,0,1-.375.375" />
        <StyledPath xmlns="http://www.w3.org/2000/svg" d="M16.135,9.751a.375.375,0,0,1,.375.375" />
        <StyledPath xmlns="http://www.w3.org/2000/svg" d="M15.76,10.126a.375.375,0,0,1,.375-.375" />
        <StyledPath xmlns="http://www.w3.org/2000/svg" d="M16.135,10.5a.375.375,0,0,1-.375-.375" />
        <StyledPath xmlns="http://www.w3.org/2000/svg" d="M16.51,10.126a.375.375,0,0,1-.375.375" />
        <StyledPath xmlns="http://www.w3.org/2000/svg" d="M7.445,4.993A3.371,3.371,0,1,0,3.784,8.966" />
        <StyledPath xmlns="http://www.w3.org/2000/svg" d="M20.236,8.966a3.367,3.367,0,1,0-3.661-3.973" />
      </svg>
    </Transform>
  </>
);

const StyledPath = styled.path`
  fill: none;
  stroke: #000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.5px;
`;
const StyledLine = styled.line`
  fill: none;
  stroke: #000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.5px;
`;
