import React, { FC } from "react";
import styled from "styled-components";
import { Transform } from "../Transform";

export const CloseIcon: FC = ({ ...props }) => (
  <>
    <Transform {...props} x={-0.5} y={-0.5} scale={0.0625}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#00c4a7" viewBox="0 0 24 24">
        <StyledLine xmlns="http://www.w3.org/2000/svg" x1="0.75" y1="23.249" x2="23.25" y2="0.749" />
        <StyledLine xmlns="http://www.w3.org/2000/svg" x1="23.25" y1="23.249" x2="0.75" y2="0.749" />
      </svg>
    </Transform>
  </>
);

const StyledLine = styled.line`
  fill: none;
  stroke: #ffffff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.5px;
`;
