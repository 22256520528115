import React, { FC } from "react";
import styled from "styled-components";
import { Transform } from "../Transform";

export const ResetIcon: FC = ({ ...props }) => (
  <>
    <Transform {...props} x={-0.38} y={-0.38} scale={0.1}>
      <svg fill="#535353" xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 25 25">
        <StyledPoly xmlns="http://www.w3.org/2000/svg" points="5.25 14.248 5.25 18.748 0.75 18.748" />
        <StyledPoly xmlns="http://www.w3.org/2000/svg" points="18.75 9.748 18.75 5.248 23.25 5.248" />
        <StyledPath xmlns="http://www.w3.org/2000/svg" d="M19.032,5.245A9.752,9.752,0,0,1,8.246,21" />
        <StyledPath xmlns="http://www.w3.org/2000/svg" d="M4.967,18.751A9.753,9.753,0,0,1,15.754,3" />
      </svg>
    </Transform>
  </>
);

const StyledPoly = styled.polyline`
  fill: none;
  stroke: #535353;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.5px;
`;

const StyledPath = styled.path`
  fill: none;
  stroke: #535353;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.5px;
`;
