import React, { FC } from "react";
import styled from "styled-components";
import { Transform } from "../Transform";

export const PlayIcon: FC = ({ ...props }) => (
  <>
    <Transform {...props} x={-0.3} y={-0.36} scale={0.1}>
      <svg fill="#535353" xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 25 25">
        <StyledPath d="M2.338,3.255v17.49a1.5,1.5,0,0,0,2.209,1.322L20.87,13.322a1.5,1.5,0,0,0,0-2.644L4.547,1.933A1.5,1.5,0,0,0,2.338,3.255Z" />
      </svg>
    </Transform>
  </>
);

const StyledPath = styled.path`
  fill: none;
  stroke: #535353;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
`;
