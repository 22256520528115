import React, { FC } from "react";
import styled from "styled-components";
import { Transform } from "../Transform";

export const StopIcon: FC = ({ ...props }) => (
  <>
    <Transform {...props} x={-0.41} y={-0.39} scale={0.105}>
      <svg fill="#535353" xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 25 25">
        <StyledRect xmlns="http://www.w3.org/2000/svg" x="5.25" y="3.75" width="4.5" height="16.5" rx="1.5" ry="1.5" />
        <StyledRect xmlns="http://www.w3.org/2000/svg" x="14.25" y="3.75" width="4.5" height="16.5" rx="1.5" ry="1.5" />
      </svg>
    </Transform>
  </>
);

const StyledRect = styled.rect`
  fill: none;
  stroke: #535353;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.5px;
`;
