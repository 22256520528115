import { FC, useEffect } from "react";
import styled from "styled-components";
import { useMobSessionService } from "../../../utils/useMobSessionService";
import { useObservable } from "../../../utils/useObservable";
import { CircularButton } from "../../common/svg/CircularButton";
import { ScreenIcon } from "../../common/svg/icons/ScreenIcon";
// import { MuteIcon } from "../../../common/svg/icons/MuteIcon";
// import { VolumeUpIcon } from "../../../common/svg/icons/VolumeUpIcon";
import { useSpeechService } from "../../../utils/useSpeechService";
import { DoorOpenIcon } from "../../common/svg/icons/LogoutIcon";
import { useFullscreenService } from "../../../utils/useFullscreenService";
import { Transform } from "../../common/svg/Transform";
import { RectButton } from "../../common/svg/RectButton";

interface Props {
  x: number;
  y: number;
}

export const SidePanel: FC<Props> = () => {
  const sessionService = useMobSessionService();
  const speechService = useSpeechService();
  const fullscreenService = useFullscreenService();
  const isFullscreen = useObservable("FullscreenControl.fullscreen", fullscreenService.fullscreen$, false);

  const muted = useObservable("ScreenControls.muted", speechService.muted$, false);

  const toggleFullscreen = () => {
    if (!isFullscreen) fullscreenService.enter();
    else fullscreenService.exit();
  };
  const toggleMute = () => (muted ? speechService.unmute() : speechService.mute());

  const leaveSession = () => sessionService.updateRemoveMe();

  useEffect(() => {
    window.history.pushState(null, "", window.location.pathname);
    window.addEventListener("popstate", () => {
      leaveSession();
    });
    return () => {
      window.removeEventListener("popstate", () => {
        leaveSession();
      });
    };
  }, []);

  return (
    <StyledDiv data-testid="general-controls">
      <StyledSVG
        width="100%"
        height="100%"
        viewBox="-1.2 -1.1 2.6 2.6"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg">
        <Transform x={0.1} y={-0.2} scale={1}>
          <StyledSmallText x="-0.8" y="-3.2">
            Enlarge
          </StyledSmallText>
          <Transform x={-0.6} y={-2.85}>
            <CircularButton title="Fullscreen mode" r={0.23} onClick={toggleFullscreen}>
              <ScreenIcon data-testid="fullscreen-icon" />
            </CircularButton>
          </Transform>
          <StyledSmallText x="-0.8" y="-2.2">
            Sound
          </StyledSmallText>
          <StyledSmallText fontSize={0.19} x="-0.80" y="-1.83">
            on
          </StyledSmallText>
          <Transform x={-0.2} y={-1.85}>
            <RectButton
              width="4"
              height="1.8"
              rx="1"
              ry="1.5"
              title={muted ? "Unmute Speech" : "Mute Speech"}
              fill="#ebe9eaff"
              r={0.2}
              onClick={toggleMute}>
              {muted && (
                <Transform x={0.8} y={-0.1}>
                  <circle r={0.5} fill="#3195e4"></circle>
                </Transform>
              )}
              {muted || (
                <Transform x={-0.5} y={-0.1}>
                  <circle r={0.5} fill="#3195e4"></circle>
                </Transform>
              )}
            </RectButton>
          </Transform>
          <StyledSmallText fontSize={0.19} x="0.29" y="-1.83">
            off
          </StyledSmallText>
          <StyledSmallText x="-0.8" y="-1">
            Set time
          </StyledSmallText>
          <Transform x={-0.5} y={-0.65}>
            <RectButton
              width="4"
              height="2"
              rx="1.2"
              ry="1.2"
              title={"Set 10 min"}
              r={0.18}
              onClick={() => {
                sessionService.updateTimerInterval(10 * 60);
                console.log("IntervalControls");
              }}>
              <StyledButtonText data-testid={"button-10"} x="0.1" y="0.2" textAnchor="middle">
                {"10:00"}
              </StyledButtonText>
            </RectButton>
          </Transform>
          <Transform x={0.3} y={-0.65}>
            <RectButton
              width="4"
              height="2"
              rx="1.2"
              ry="1.2"
              title={"Set 15 min"}
              r={0.18}
              onClick={() => {
                sessionService.updateTimerInterval(15 * 60);
              }}>
              <StyledButtonText data-testid={"button-15"} x="0.1" y="0.2" textAnchor="middle">
                {"15:00"}
              </StyledButtonText>
            </RectButton>
          </Transform>
          <Transform x={-0.5} y={-0.15}>
            <RectButton
              width="4"
              height="2"
              rx="1.2"
              ry="1.2"
              title={"Set 20 min"}
              r={0.18}
              onClick={() => {
                sessionService.updateTimerInterval(20 * 60);
              }}>
              <StyledButtonText data-testid={"button-20"} x="0.1" y="0.2" textAnchor="middle">
                {"20:00"}
              </StyledButtonText>
            </RectButton>
          </Transform>
          <Transform x={0.3} y={-0.15}>
            <RectButton
              width="4"
              height="2"
              rx="1.2"
              ry="1.2"
              title={"Set 30 min"}
              r={0.18}
              onClick={() => {
                sessionService.updateTimerInterval(30 * 60);
              }}>
              <StyledButtonText data-testid={"button-30"} x="0.1" y="0.2" textAnchor="middle">
                {"30:00"}
              </StyledButtonText>
            </RectButton>
          </Transform>
          <Transform x={1} y={3.9}>
            <RectButton
              width="2"
              height="2"
              rx="0.2"
              ry="0.2"
              title="Leave session"
              fill="#fb4338"
              r={0.2}
              onClick={leaveSession}>
              <DoorOpenIcon data-testid="leave-icon" />
            </RectButton>
          </Transform>
        </Transform>
      </StyledSVG>
    </StyledDiv>
  );
};
const StyledDiv = styled.div`
  padding: 0px 0px 0px 0px;
  width: 100%;
  transform: translate(-15%, 0);
  height: 100%;
  /* margin-left: -15%; */
  border-radius: 0 15px 15px 0;
  background: #ebe9eaff;
  box-shadow: #d1cfd0 1px 1px 1px, #d1cfd0 2px 2px 2px, #d1cfd0 4px 4px 4px, #d1cfd0 8px 8px 8px, #d1cfd0 16px 16px 16px,
    #e2e2e2 -1px -1px 1px, #eeecee -2px -2px 2px, #eeecee -4px -4px 4px, #eeecee -8px 0px 8px, #eeecee -16px 0px 16px;
`;

const StyledSmallText = styled.text`
  font-family: "Work Sans", sans-serif;
  font-size: ${props => (props.fontSize ? props.fontSize : "0.23px")};
  font-weight: 700;
  fill: #535353ff;
`;

const StyledButtonText = styled.text`
  font-size: 0.7px;
  font-weight: 700;
  font-family: "Work Sans", sans-serif;
  fill: #535353;
`;

const StyledSVG = styled.svg`
  max-width: 100%;
  max-height: 100%;
  -webkit-filter: drop-shadow(5px 5px 5px rgba(192, 192, 192, 0.7)) drop-shadow(-5px -5px 5px rgba(255, 255, 255, 0.9));
  filter: drop-shadow(5px 5px 5px rgba(192, 192, 192, 0.7)) drop-shadow(-5px -5px 5px rgba(255, 255, 255, 0.9));
`;
