import React, { FC } from "react";
import styled from "styled-components";
import { Transform } from "../Transform";

export const CatIcon: FC = ({ ...props }) => (
  <>
    <Transform {...props} x={-0.3} y={-0.35} scale={0.1}>
      <svg fill="#535353" xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 25 25">
        <StyledPath
          xmlns="http://www.w3.org/2000/svg"
          d="M3.511,16.04A7.705,7.705,0,0,1,3,13.382C3,9.305,7.029,6,12,6"
        />
        <StyledPath
          xmlns="http://www.w3.org/2000/svg"
          d="M16.5,21.07A8.115,8.115,0,0,1,12,22.5a8.115,8.115,0,0,1-4.5-1.43"
        />
        <StyledPath
          xmlns="http://www.w3.org/2000/svg"
          d="M16.5,21.07A8.115,8.115,0,0,1,12,22.5a8.115,8.115,0,0,1-4.5-1.43"
        />
        <StyledPath
          xmlns="http://www.w3.org/2000/svg"
          d="M16.5,21.07A8.115,8.115,0,0,1,12,22.5a8.115,8.115,0,0,1-4.5-1.43"
        />
        <StyledPath
          xmlns="http://www.w3.org/2000/svg"
          d="M9.75,19.5A2.774,2.774,0,0,0,12,18a2.774,2.774,0,0,0,2.25,1.5"
        />
        <StyledPath xmlns="http://www.w3.org/2000/svg" d="M7.875,12a.375.375,0,0,1,.375.375" />
        <StyledPath xmlns="http://www.w3.org/2000/svg" d="M7.5,12.375A.375.375,0,0,1,7.875,12" />
        <StyledPath xmlns="http://www.w3.org/2000/svg" d="M7.875,12.75a.375.375,0,0,1-.375-.375" />
        <StyledPath xmlns="http://www.w3.org/2000/svg" d="M8.25,12.375a.375.375,0,0,1-.375.375" />
        <StyledPath xmlns="http://www.w3.org/2000/svg" d="M16.125,12a.375.375,0,0,1,.375.375" />
        <StyledPath xmlns="http://www.w3.org/2000/svg" d="M15.75,12.375A.375.375,0,0,1,16.125,12" />
        <StyledPath xmlns="http://www.w3.org/2000/svg" d="M16.125,12.75a.375.375,0,0,1-.375-.375" />
        <StyledPath xmlns="http://www.w3.org/2000/svg" d="M16.5,12.375a.375.375,0,0,1-.375.375" />
        <StyledLine xmlns="http://www.w3.org/2000/svg" x1="12" y1="6" x2="12" y2="9.75" />
        <StyledLine xmlns="http://www.w3.org/2000/svg" x1="9" y1="6.75" x2="9" y2="9" />
        <StyledLine xmlns="http://www.w3.org/2000/svg" x1="15" y1="6.75" x2="15" y2="9" />
        <StyledPath xmlns="http://www.w3.org/2000/svg" d="M3,13.381V3.121A1.5,1.5,0,0,1,5.437,1.95l5.172,4.138" />
        <StyledPath xmlns="http://www.w3.org/2000/svg" d="M21,13.381V3.121A1.5,1.5,0,0,0,18.563,1.95L13.391,6.088" />
        <StyledLine xmlns="http://www.w3.org/2000/svg" x1="18.75" y1="15.75" x2="23.25" y2="16.5" />
        <StyledLine xmlns="http://www.w3.org/2000/svg" x1="18" y1="18" x2="21.75" y2="20.25" />
        <StyledLine xmlns="http://www.w3.org/2000/svg" x1="5.25" y1="15.75" x2="0.75" y2="16.5" />
        <StyledLine xmlns="http://www.w3.org/2000/svg" x1="6" y1="18" x2="2.25" y2="20.25" />
      </svg>
    </Transform>
  </>
);

const StyledPath = styled.path`
  fill: none;
  stroke: #000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.5px;
`;
const StyledLine = styled.line`
  fill: none;
  stroke: #000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.5px;
`;
