import React, { FC } from "react";
import styled from "styled-components";
import { Transform } from "../Transform";

export const InsectIcon: FC = ({ ...props }) => (
  <>
    <Transform {...props} x={-0.3} y={-0.35} scale={0.1}>
      <svg fill="#535353" xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 25 25">
        <StyledPath
          xmlns="http://www.w3.org/2000/svg"
          d="M6.03,11.283C6.258,7.184,8.463,4.5,12.01,4.5s5.752,2.684,5.98,6.783"
        />
        <StyledPath
          xmlns="http://www.w3.org/2000/svg"
          d="M17.9,14.982C17.324,18.646,14.667,21,12.01,21S6.7,18.646,6.123,14.982"
        />
        <StyledPoly xmlns="http://www.w3.org/2000/svg" points="14.055 4.841 16.51 0.75 23.26 7.5" />
        <StyledCircle xmlns="http://www.w3.org/2000/svg" cx="17.635" cy="13.125" r="1.875" />
        <StyledCircle xmlns="http://www.w3.org/2000/svg" cx="6.385" cy="13.125" r="1.875" />
        <StyledPath xmlns="http://www.w3.org/2000/svg" d="M14.778,20.134c1.234.617,1.41,1.832.982,3.116" />
        <StyledPoly xmlns="http://www.w3.org/2000/svg" points="9.965 4.841 7.51 0.75 0.76 7.5" />
        <StyledPath xmlns="http://www.w3.org/2000/svg" d="M9.24,20.135c-1.23.615-1.408,1.83-.98,3.115" />
      </svg>
    </Transform>
  </>
);

const StyledPath = styled.path`
  fill: none;
  stroke: #000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.5px;
`;
const StyledPoly = styled.polyline`
  fill: none;
  stroke: #000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.5px;
`;

const StyledCircle = styled.circle`
  fill: none;
  stroke: #000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.5px;
`;
