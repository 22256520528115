import React, { FC } from "react";
import styled from "styled-components";
import { Transform } from "../Transform";

interface Props {
  fill?: string;
}

export const DoorOpenIcon: FC<Props> = ({ fill, ...props }: Props) => (
  <>
    <Transform {...props} x={-1} y={-0.4} scale={0.1}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="8"
        fill={fill}
        className="bi bi-door-open-fill"
        viewBox="0 0 25 25">
        <StyledLine xmlns="http://www.w3.org/2000/svg" x1="0.75" y1="12.004" x2="16.5" y2="12.004" />
        <StyledPoly xmlns="http://www.w3.org/2000/svg" points="12.75 15.754 16.5 12.004 12.75 8.254" />
        <StyledPath xmlns="http://www.w3.org/2000/svg" d="M3.306,16.6a10.5,10.5,0,1,0,.179-9.542" />
      </svg>
    </Transform>
  </>
);

const StyledLine = styled.line`
  fill: none;
  stroke: #ffffff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.5px;
`;

const StyledPath = styled.path`
  fill: none;
  stroke: #ffffff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.5px;
`;

const StyledPoly = styled.polyline`
  fill: none;
  stroke: #ffffff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.5px;
`;
