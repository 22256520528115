import { FC } from "react";
import { Columns } from "react-bulma-components";
import styled from "styled-components";
// import { StyledLeftFrame } from "../components_styles/StyledLeftFrame";
import { useFullscreenService } from "../../utils/useFullscreenService";
import { useObservable } from "../../utils/useObservable";
import { Timer } from "./SessionScreen/Timer";
import { SidePanel } from "./SessionScreen/SidePanel";
export const SessionScreen: FC<{}> = () => {
  const fullscreenService = useFullscreenService();
  const isFullscreen = useObservable("SessionScreen.isFullscreen", fullscreenService.fullscreen$, false);

  return (
    <>
      {!isFullscreen ? (
        <StyledColumns className="row is-one-third ">
          <StyledColumn className="column is-6">
            <Timer />
          </StyledColumn>
          <div className="column is-3 ">
            <SidePanel x={-0.5} y={-2.2} />
          </div>
        </StyledColumns>
      ) : (
        <StyledFullscreen data-testid="fullscreen-mode" className="columns">
          <StyledColumn className="column is-6">
            <Timer />
          </StyledColumn>
          <div className="column is-3">
            <SidePanel x={-0.5} y={-2.2} />
          </div>
        </StyledFullscreen>
      )}
    </>
  );
};

const StyledColumn = styled.div`
  height: 100%;
  z-index: 100;
  border-radius: 20px;
  background: #ebe9eaff;
  box-shadow: #d1cfd0 1px 1px 1px, #d1cfd0 2px 2px 2px, #d1cfd0 4px 4px 4px, #d1cfd0 8px 8px 8px, #d1cfd0 16px 16px 16px,
    #e2e2e2 -1px -1px 1px, #eeecee -2px -2px 2px, #eeecee -4px -4px 4px, #eeecee -8px 0px 8px, #eeecee -16px 0px 16px;
`;

const StyledFullscreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
const StyledColumns = styled(Columns)`
  @media screen and (min-width: 769px) {
    transform: translate(15%, 0);
  }
  height: 100%;
  max-width: 1200px !important;
  margin: auto;
  padding-top: 4rem;
  padding-bottom: 2rem;
  display: flex;
`;
