import React, { FC } from "react";
import styled from "styled-components";
import { Clock } from "./Timer/Clock";
import { ClockControls } from "./Timer/ClockControls";
import { ParticipantControls } from "./Timer/ParticipantControls";

interface Props {}

// eslint-disable-next-line no-empty-pattern
export const Timer: FC<Props> = ({}: Props) => {
  return (
    <div data-testid="timer" style={{ width: "100%", height: "100%" }}>
      <StyledSVG viewBox="-1 -1 4 6" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <Clock data-testid="clock" />
        <ClockControls />
        <ParticipantControls />
      </StyledSVG>
    </div>
  );
};

const StyledSVG = styled.svg`
  max-width: 100%;
  max-height: 100%;
  -webkit-filter: drop-shadow(5px 5px 5px rgba(192, 192, 192, 0.7)) drop-shadow(-5px -5px 5px rgba(255, 255, 255, 0.9));
  filter: drop-shadow(5px 5px 5px rgba(192, 192, 192, 0.7)) drop-shadow(-5px -5px 5px rgba(255, 255, 255, 0.9));
`;
