import React, { FC, ReactNode } from "react";
import styled from "styled-components";

interface Props {
  title?: string;
  r: number;
  onClick: () => void;
  children: ReactNode;
  width: string;
  height: string;
  rx: string;
  ry: string;
  fill?: string;
}

export const RectButton: FC<Props> = ({
  fill,
  width,
  height,
  rx,
  ry,
  title,
  r,
  onClick,
  children,
  ...props
}: Props) => (
  <StyledClickableGroup {...props} transform={`scale(${r})`}>
    <StyledShadowButton onClick={() => onClick()}>
      {title && <title>{title}</title>}
      <g onClick={() => onClick()}>
        <StyledBackgroundRect fill={fill} x="-1.8" y="-1" width={width} height={height} rx={rx} ry={ry} />
      </g>
      <g transform="scale(1.4)">{children}</g>
    </StyledShadowButton>
  </StyledClickableGroup>
);

const StyledBackgroundRect = styled.rect`
  fill: ${props => (props.fill ? props.fill : "#ebe9eaff")};
`;

const StyledClickableGroup = styled.g`
  cursor: pointer;
`;

const StyledShadowButton = styled.g`
  &:hover {
    filter: drop-shadow(0px 0px 0.05px #6e6e6e);
  }
`;
