import React, { FC } from "react";
import styled, { keyframes } from "styled-components";
import { formatTime } from "../../../../utils/formatTime";
import { useTimerService } from "../../../../utils/useTimerService";
import { useObservable } from "../../../../utils/useObservable";
import { Transform } from "../../../common/svg/Transform";
// import { CircularButton } from "../../../common/svg/CircularButton";
// import { ResetIcon } from "../../../common/svg/icons/ResetIcon";
// import { PlayIcon } from "../../../common/svg/icons/PlayIcon";
// import { StopIcon } from "../../../common/svg/icons/StopIcon";

interface Props {}

// eslint-disable-next-line no-empty-pattern
export const Clock: FC<Props> = ({}: Props) => {
  const timerService = useTimerService();
  const time = useObservable("Timer.time", timerService.timeComponents$, { mins: 0, secs: 0 });
  const percent = useObservable("Timer.interval", timerService.percent$, 0) || 0;
  const running = useObservable("Timer.running", timerService.running$, false);

  // Path calculation based on:
  // https://medium.com/hackernoon/a-simple-pie-chart-in-svg-dbdd653b6936
  const arcX = Math.cos(2 * Math.PI * percent);
  const arcY = Math.sin(2 * Math.PI * percent);
  const path = [`M 1 0`, `A 1 1 0 ${percent > 0.5 ? 1 : 0} 1 ${arcX} ${arcY}`, `L 0 0`].join(" ");

  return (
    <Transform x={1} y={0.8} scale={1.8}>
      <filter id="background-circle">
        <feOffset dx="0" dy="0" />

        <feGaussianBlur stdDeviation="0.1" result="offset-blur" />

        <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse" />

        <feFlood floodColor="#2e97e7" result="color" />
        <feComposite operator="in" in="color" in2="inverse" result="shadow" />

        <feComposite operator="over" in="shadow" in2="SourceGraphic" />
      </filter>
      <StyledBackgroundCircle className={running ? "running" : ""} cx="0" cy="0" r="0.9" />
      <g transform="rotate(-90) scale(0.9)">
        <StyledWedgePath d={path} />
      </g>
      <StyledInnerCircle cx="0" cy="0" r="0.75" />
      {time && (
        <StyledTimerText v-Model="type" data-testid="timer-text" x="0" y="0.15" textAnchor="middle">
          {formatTime(time)}
        </StyledTimerText>
      )}
    </Transform>
  );
};

const darkGrey = "#ebe9eaff";

const colorShift = keyframes`
        0% {
        fill: #8bbfe8;
        }
        50% {
        fill: #a9d2d4;
        }
        100% {
        fill: #8bbfe8;
        }
        `;

const StyledBackgroundCircle = styled.circle`
  fill: #3195e4;
  filter: url(#background-circle);

  &.running {
    animation: ${colorShift} 2s linear infinite;
  }
`;

const StyledWedgePath = styled.path`
  fill: #ffffff;
`;

const StyledInnerCircle = styled.circle`
  fill: ${darkGrey};
  filter: drop-shadow(0 0 0.05px #494949);
`;

const StyledTimerText = styled.text`
  //  font-family: "Arial-BoldMT", "Arial", sans-serif;
  /* stroke: #666666; */
  //stroke-width: 0.00001px;
  font-family: "Work Sans", sans-serif;
  font-weight: 500;
  font-size: 0.4px;
  fill: #535353;
`;
